import axios from 'axios';
// 统一请求路径前缀
let basedg = '/uk';

/**
 * 无需token验证的GET请求 避免旧token过期导致请求失败
 * @param {*} url 
 * @param {*} params 
 */
export const getNoAuthRequestDgApi = (url, params) => {
    return axios({
        method: 'get',
        url: `${basedg}${url}`,
        params: params
    });
};
export const postNoAuthRequestDgApi = (url, params) => {
    return axios({
        method: 'post',
        url: `${basedg}${url}`,
        data: params,
        transformRequest: [function(data) {
            let ret = '';
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
            }
            ret = ret.substring(0, ret.length - 1);
            return ret;
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};
/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.localStorage.setItem(name, content);
};

/**
 * 获取localStorage
 */
export const getStore = name => {
    if (!name) return;
    let v = window.localStorage.getItem(name);
    if (v == null) {
        return "";
    }
    return v;
};

/**
 * 删除localStorage
 */
export const removeStore = name => {
    if (!name) return;
    window.localStorage.removeItem(name);
};
export const getWorkShopDataById = (id, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/getWorkshopInfor/${id}`, params)
}
export const getDay7WageEcharts = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/Day7WageEcharts`, params)
}
export const getDay7WageEchartsWorkShop = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/Day7WageEchartsWorkShop`, params)
}
export const Day7WageTotailEcharts = (deptId, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/Day7WageTotailEcharts/${deptId}`, params)
}
export const getWageListapi = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/selectWageList`, params)
}
export const selectOrderFailBigList = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/selectOrderFailBigList`, params)
}
export const bigGroupTotailInfor = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/bigGroupTotailInfor`, params)
}
export const selectCutTotal = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/selectCutTotal`, params)
}
export const selectJobNoisSolve = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/selectJobNoisSolve`, params)
}
export const findJobList = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/findJobList`, params)
}
export const findBigOrderInforList = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/getBigOrderInforList`, params)
}
export const selectJobPie = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/selectJobPie`, params)
}
export const qaSpotBar = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/qaSpotBar`, params)
}
export const selectJobTotailInfor = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/selectJobTotailInfor`, params)
}
export const getWageListBigapi = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/selectWageListBig`, params)
}
export const getWorkSeqTotail = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/selectWorkSeqTotail`, params)
}

export const selectProductionTotal = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectProductionTotal`, params)
}
export const selectlyProductionTotal = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectlyProductionTotal`, params)
}
export const selectWorkBigTotal = (workId, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectWorkBigTotal/${workId}`, params)
}
export const selectTraDitionProductionTotal = (workId, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectTraDitionProductionTotal/${workId}`, params)
}
export const getBigOrderQaList10 = (totailType, orderId, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/getBigOrderQaList10/${totailType}/${orderId}`, params)
}
export const selectQaTotail = (deptId, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectQaTotail/${deptId}`, params)
}
export const getDay7SeqNameCount = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/Day7SeqNameCount`, params)
}
export const getDay7SeqNameCountBig = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/Day7SeqNameCountBig`, params)
}
export const selectSeqYieldByDateNow = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/selectSeqYieldByDateNow`, params)
}
export const Day7ProductionCount = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/Day7ProductionCount`, params)
}
export const selectQACount = (deptId, totailType, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectQACount/${deptId}/${totailType}`, params)
}
export const selectSeqYieldCount = (deptId, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectSeqYieldCount/${deptId}`, params)
}

export const Day7TraDitionQACount = (workId,totailType, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/Day7TraDitionQACount/${workId}/${totailType}`, params)
}
export const Day7QACount = ( totailType, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/Day7QACount/${totailType}`, params)
}
export const Day7LyQACount = ( totailType, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/Day7LyQACount/${totailType}`, params)
}
export const selectWorkDay7ProducitonCount = (workId, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/WorkDay7ProducitonCount/${workId}`, params)
}
export const selectWageSeqNumList = (workId, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectWageSeqNumList/${workId}`, params)
}
export const selectWxOrderListBig = (empNo, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectWxOrderListBig/${empNo}`, params)
}
export const selectWxOrderUserInfor = (empNo, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectWxOrderUserInfor/${empNo}`, params)
}
export const findInventoryAbnormal = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/findInventoryAbnormal`, params)
}
export const findInventoryWarn = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/findInventoryWarn`, params)
}
export const findInventoryInfor = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/findInventoryInfor`, params)
}
export const findInventoryMonth = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/findInventoryMonth`, params)
}
export const findCutList = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/findCutList`, params)
}
export const findCutInventoryList = (params) => {
        return postNoAuthRequestDgApi(`/BigDataScreen/findCutInventoryList`, params)
    }
    // export const getWorkList = (params) => {
    //     return getNoAuthRequestDgApi('/workshopInfor/getAllByType', params)
    // }

// 传统工厂数据展示
export const findPlanWareOutList = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/findPlanWareOutList`, params)
}
export const findBigCutPlanWareOutList = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/findBigCutPlanWareOutList`, params)
}
export const findBigCutWareList = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/findBigCutWareList`, params)
}
export const findWareOutNumEchart = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/findWareOutNumEchart`, params)
}
export const findMaterInventoryList = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/findMaterInventoryList`, params)
}
export const getMaterUserAccountsList = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/getMaterUserAccountsList`, params)
}
export const getWareNoBatchingNum = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/getWareNoBatchingNum`, params)
}
export const findBigWareShowList = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/findBigWareShowList`, params)
}
export const Day7QaProductionCount = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/Day7QaProductionCount`, params)
}
export const Day7WareCutCount = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/Day7WareCutCount`, params)
}
export const CtDay7QACount = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/CtDay7QACount`, params)
}

export const ctwxordershow = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/wxordershow`, params)
}
export const selectBigYwsOrderMainTotal = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectBigYwsOrderMainTotal`, params)
}
export const ctordershow = (params) => {
    return postNoAuthRequestDgApi(`/BigDataScreen/ordershow`, params)
}
export const selectBigOrderMainTotal = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectBigOrderMainTotal`, params)
}
export const selectBigWorkYiedTotal = (workId, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectBigWorkYiedTotal/${workId}`, params)
}

export const selectWorkCheck7DayCharts = (workId, params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectWorkCheck7DayCharts/${workId}`, params)
}
export const selectBigOrderYiedTotal = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectBigOrderYiedTotal`, params)
}
export const selectBigOrderProductionMainTotal = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectBigOrderProductionMainTotal`, params)
}

export const selectOrderCheck7DayCharts = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectOrderCheck7DayCharts`, params)
}

export const selectBigOrderProductionTotal = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectBigOrderProductionTotal`, params)
}

export const selectOrderProduction7DayCharts = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectOrderProduction7DayCharts`, params)
}

export const selectWareProducitonTotal = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectWareProducitonTotal`, params)
}
export const Day7ProductionCountInw = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/Day7ProductionCountInw`, params)
}
export const Day7TraDitionInwReCount = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/Day7TraDitionInwReCount`, params)
}

export const selectTraDitionCutTotal = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/selectTraDitionCutTotal`, params)
}
export const Day7TraDitionCutCountInw = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/Day7TraDitionCutCountInw`, params)
}
export const Day7TraDitionCutOutCount = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/Day7TraDitionCutOutCount`, params)
}
export const findTraDitionBigWareShowList = (params) => {
    return getNoAuthRequestDgApi(`/BigDataScreen/findTraDitionBigWareShowList`, params)
}